<template>
  <v-layout column>
    <Autocomplete
      :value.sync="internalValue"
      label="Grupa zawodowa"
      :data="items"
      :clearAfterSelection="false"
      :returnObject="false"
      :disabled="disabled"
      :required="required"
      placeholder="Wybierz grupę zawodową..."
    >
      <InfoButton
        v-if="infoText"
        :text="infoText"
        slot="label-icon"
      ></InfoButton>
    </Autocomplete>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
    },
  },
  data() {
    return {
      items: [
        { value: 6, text: "Fizjoterapeuta" },
        { value: 11, text: "Lekarz" },
        { value: 14, text: "Masażysta (technik masażysta)" },
        { value: 3, text: "Dietetyk" },
        { value: 21, text: "Psychoterapeuta" },
        { value: 50, text: "Psycholog" },
        { value: 31, text: "Terapeuta zajęciowy" },
        { value: 36, text: "Terapeuta środowiskowy" },
        { value: 1, text: "Asystentka medyczna" },
        { value: 2, text: "Diagnosta labolatoryjny" },
        { value: 4, text: "Farmaceuta" },
        { value: 5, text: "Felczer" },
        { value: 7, text: "Higienistka dentystyczna" },
        { value: 8, text: "Higienistka szkolna" },
        { value: 9, text: "Instruktor higieny" },
        { value: 33, text: "Instruktor terapii uzależnień" },
        { value: 34, text: "Specjalista psychoterapii uzależnień" },
        { value: 12, text: "Lekarz dentysta" },
        { value: 13, text: "Logopeda" },
        { value: 15, text: "Opiekunka dziecięca" },
        { value: 32, text: "Opiekun medyczny" },
        { value: 16, text: "Optometrysta" },
        { value: 17, text: "Ortoptystyka" },
        { value: 18, text: "Pielęgniarka" },
        { value: 19, text: "Położna" },
        { value: 20, text: "Protetyk słuchu" },
        { value: 22, text: "Pracownik medyczny" },
        { value: 23, text: "Specjalista zdrowia publicznego" },
        { value: 24, text: "Technik analityki medycznej" },
        { value: 25, text: "Technik dentystyczny" },
        { value: 26, text: "Technik farmaceutyczny" },
        { value: 27, text: "Technik elektroniki medycznej" },
        { value: 28, text: "Technik elektroradiolog" },
        { value: 29, text: "Technik optyk" },
        { value: 30, text: "Technik ortopeda" },
        { value: 35, text: "Pedagog specjalny" },
      ],
    };
  },
  components: {
    Autocomplete: () => import("@/components/Autocomplete"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
};
</script>